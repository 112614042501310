import { Auth } from 'aws-amplify';
import axios from 'axios';

import { GetStatisticsResponseType, GetStatsRequestParamsProps, StatisticsType } from '@/Types';

export const getStatistics = async <T extends GetStatisticsResponseType>({
  partnerPublicId,
  statsType,
  startDate,
  endDate,
}: GetStatsRequestParamsProps) => {
  const currentSession = await Auth.currentSession();
  const customHeaders = {
    Authorization: 'Bearer ' + currentSession.getIdToken().getJwtToken(),
  };

  const instance = axios.create({
    timeout: 30000,
    headers: customHeaders,
  });

  const endpoint = `${process.env.REACT_APP_STATISTICS_ENDPOINT}graphql`;

  const defaultFilter = {
    partner_id: { eq: [partnerPublicId] },
    date: { gte: startDate, lte: endDate },
  };

  let body = {};
  let selector = '';

  switch (statsType) {
    case StatisticsType.Overview: {
      body = {
        query: `
     {
    PartnerCheckins(
        filter: ${JSON.stringify(defaultFilter).replace(/"([^"]+)":/g, '$1:')}
    ) {
        start_day
        end_day
        data {
			ymd
			checkins {
				checkin_timestamp_localtime, checkout_timestamp_localtime,
				checkin_year_month_day,
				checkin_time, checkout_time,
				visit_duration, value, checkin_state, course_type,checkin_type,
				eu_id
				company_id, company_cas_id, company_pimcore_id, company_name
			}
        }
    }
}
    `,
      };

      selector = 'PartnerCheckins';
      break;
    }

    case StatisticsType.Company: {
      body = {
        query: `
       {
      PartnerCheckinsByCompany(
          filter: ${JSON.stringify(defaultFilter).replace(/"([^"]+)":/g, '$1:')}
      ) {
        start_day
        end_day
        total_checkins
        data {
            company_id
            company_cas_id
            company_pimcore_id
            company_name
            checkins
			eus
    }
      }
  }
      `,
      };

      selector = 'PartnerCheckinsByCompany';
      break;
    }

    case StatisticsType.Member: {
      body = {
        query: `
       {
    PartnerCheckinsByMember(
        filter: ${JSON.stringify(defaultFilter).replace(/"([^"]+)":/g, '$1:')}
    ) {
        start_day
        end_day
        total_checkins
        data {
            company_id, company_cas_id, company_pimcore_id, company_name
            eu_id, checkins
        }
    }
}
      `,
      };

      selector = 'PartnerCheckinsByMember';
      break;
    }

    case StatisticsType.Training: {
      body = {
        query: `
       {
    PartnerTrainingInstructions (
        filter: ${JSON.stringify(defaultFilter).replace(/"([^"]+)":/g, '$1:')}
    ) {
        start_day
        end_day
        data {
			checkin_year_month_day, checkout_year_month_day
			eu_id, company_name
			coupon_issue_date, coupon_redemption_date
			company_id, company_cas_id, company_pimcore_id
        }
    }
}
      `,
      };

      selector = 'PartnerTrainingInstructions';
      break;
    }
  }

  const query = await instance.post<Record<'data', Record<string, T>>>(endpoint, body);

  return query.data.data[selector] || {};
};
