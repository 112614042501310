import { useFormikContext } from 'formik';
import { ReactChild, useCallback, useRef } from 'react';

import { RegisterPartnerService } from '@/Services';

type ValuesType = {
  [key: string]: Record<string, string>[];
};
type MultiInputProps = {
  registerPartnerService: RegisterPartnerService | undefined;
  children: (
    autoTab: (
      event: React.KeyboardEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>,
      index: number,
    ) => void,
    handleChange: (value: string, index: number) => void,
    inputsRef: React.RefObject<HTMLInputElement[]>,
    handlePaste: (e: ClipboardEvent, maxLength: number) => void,
  ) => ReactChild;
  name: keyof ValuesType;
  subName: keyof ValuesType[keyof ValuesType][number];
  validateOnChange: boolean;
};

export const MultiInput = (props: MultiInputProps) => {
  const { registerPartnerService, children, name, subName, validateOnChange } = props;
  const { values, setFieldValue } = useFormikContext<ValuesType>();
  const inputsRef = useRef<HTMLInputElement[]>([]);

  const autoTab = useCallback(
    (
      e: React.KeyboardEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>,
      idx: number,
    ) => {
      const prevValue = values[name][idx][`${subName}Old`];
      const isNext = registerPartnerService?.isNext(e as React.ChangeEvent<HTMLInputElement>);
      const isPrevious = registerPartnerService?.isPrev(
        e as React.KeyboardEvent<HTMLInputElement>,
        prevValue,
      );
      const nextIdx = idx + 1;
      const prevIdx = idx - 1;
      const next = inputsRef.current[nextIdx];
      const prev = inputsRef.current[prevIdx];

      // Default move
      if (registerPartnerService?.isDefaultMove(e as React.KeyboardEvent)) return;

      if (isPrevious && prev && !e.currentTarget.value && prevValue) {
        prev.focus();
        return;
      }

      if (isNext && next) {
        next.focus();
      } else {
        if (name !== 'startCode') {
          const tmp: HTMLInputElement = document.createElement('input');
          document.body.appendChild(tmp);
          tmp.focus();
          document.body.removeChild(tmp);
        }
      }
    },
    [inputsRef, values, registerPartnerService, name, subName],
  );

  const handleChange = useCallback(
    (value: string, idx: number) => {
      const formikName = `${name}.${idx}.${subName}`;
      const formikNameOld = `${name}.${idx}.${subName}Old`;
      const oldNewValue = values[name][idx][subName];

      setTimeout(() => {
        setFieldValue(formikName, value, validateOnChange);
        setFieldValue(formikNameOld, oldNewValue);
      });
    },
    [setFieldValue, values, name, subName, validateOnChange],
  );

  const handlePaste = useCallback(
    (e: ClipboardEvent, maxLength: number) => {
      if (!e.clipboardData) return;

      const paste = e.clipboardData.getData('text');

      const pasteReg = new RegExp(`.{1,${maxLength}}`, 'g');
      const chunks = paste.replace(/[-.,]/g, '').match(pasteReg);

      if (chunks) {
        chunks.forEach((chunk: string, idx: number) => {
          const input = inputsRef.current[idx];
          if (input) {
            input.value = chunk;
            handleChange(chunk, idx);
          }
        });
      }
    },
    [handleChange],
  );

  return <>{children(autoTab, handleChange, inputsRef, handlePaste)}</>;
};
