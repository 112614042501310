import moment from 'moment';

import { LanguageType } from '@/Types';

export const formatRowValue = (value: string, formatTo: 'DAY' | 'TIME') => {
  if (!value) return '';
  if (formatTo === 'DAY') {
    return `${value.substring(6)}.${value.substring(4, 6)}.${value.substring(0, 4)}`;
  }
  return `${value.substring(0, value.length - 2)}:${value.slice(-2)}`;
};

export const formatDate = (date: Date) => moment(date).format('DD.MM.YYYY');
export const formatDateQl = (date: Date) => moment(date).format('YYYY-MM-DD');

export const formatDateWithLocale = (
  value: Date | number,
  options: Intl.DateTimeFormatOptions,
  language: LanguageType,
) => {
  const locale = language === 'en' ? 'en-GB' : 'de';
  return new Intl.DateTimeFormat(locale, options).format(new Date(value));
};
