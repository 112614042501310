import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAppContext } from '@/Context';
import { PartnerServiceFactory } from '@/Services';

import useCurrentPartnerIdQuery from './useCurrentPartnerIdQuery';

const useDashboardStatsQuery = () => {
  const { dispatch } = useAppContext();
  const partnerServiceInstance = useMemo(
    () => new PartnerServiceFactory().getInstance(dispatch),
    [dispatch],
  );
  const { data: casPublicId = '' } = useCurrentPartnerIdQuery();

  const queryResult = useQuery(
    ['getDashboardStats', casPublicId],
    async () => {
      const response = await partnerServiceInstance.getStats(casPublicId);

      return (
        response.data || {
          performedEvents: 0,
          eventsPerWeek: [],
          top3Events: [],
        }
      );
    },
    {
      cacheTime: 0,
      enabled: !!casPublicId,
    },
  );

  return queryResult;
};

export default useDashboardStatsQuery;
