import { Box, Button, Grid } from '@mui/material';
import clsx from 'clsx';
import { Field, useFormikContext } from 'formik';
import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormikSwitch } from '@/App/Shared/Form/Components/Formik/FormikSwitch';
import { FormikTextField } from '@/App/Shared/Form/Components/Formik/FormikTextField';
import { SubHeader } from '@/App/Shared/Form/Components/Header/SubHeader';
import { SwitchLabel } from '@/App/Shared/Form/Components/Label/SwitchLabel';
import DeleteIcon from '@/Static/Icons/atoms-symbols-user-delete.svg';
import { InvitationFormik, InvitationsFormik } from '@/Types';

import { useInvitationStyles } from './Invitation.styles';

type InvitationProps = {
  idx: number;
  invitation: InvitationFormik;
  changeCtaState: () => void;
};

export const Invitation = (props: InvitationProps): JSX.Element => {
  const { classes } = useInvitationStyles();
  const intl = useIntl();
  const { idx, invitation } = props;
  const { setFieldValue, values, isValid } = useFormikContext<InvitationsFormik>();
  const minInvitation = 1;
  const handleChangeRole = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedRole = event.target.checked ? 'Admin' : 'User';
      setFieldValue(`invitations[${idx}].role`, updatedRole);
    },
    [setFieldValue, idx],
  );
  const deleteInvitation = useCallback(() => {
    const invitations = [...values.invitations];

    if (invitations.length > minInvitation) {
      const newInvitations = invitations.filter((_, index) => index !== idx);

      setFieldValue('invitations', newInvitations);
      if (isValid) props.changeCtaState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitation, values]);

  return (
    <Grid item xs={12}>
      <Grid
        container
        className={clsx(classes.invitationWrapper, {
          [classes.invitationWrapperOdd]: idx % 2 !== 0,
        })}>
        {values.invitations.length > minInvitation && (
          <Button className={classes.deleteBtn} onClick={deleteInvitation}>
            <img alt='' src={DeleteIcon} />
          </Button>
        )}

        <Grid className={classes.inputContainer} item xs={11}>
          <Box className={classes.nameContainer}>
            <Field
              component={FormikTextField}
              variant='outlined'
              type='text'
              name={`invitations[${idx}].name`}
              label={
                <FormattedMessage
                  id='form.invite_user.field.name.label'
                  defaultMessage='First name*'
                />
              }
            />
            <Box className={classes.spacer} />
            <Field
              component={FormikTextField}
              variant='outlined'
              type='text'
              name={`invitations[${idx}].surname`}
              label={
                <FormattedMessage
                  id='form.invite_user.field.surname.label'
                  defaultMessage='Last name*'
                />
              }
            />
          </Box>
        </Grid>
        <Grid className={classes.inputContainer} item xs={11}>
          <Field
            component={FormikTextField}
            variant='outlined'
            type='text'
            name={`invitations[${idx}].email`}
            label={
              <FormattedMessage
                id='form.invite_user.field.email.label'
                defaultMessage='E-mail address*'
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <SubHeader
              title={intl.formatMessage({
                id: 'form.invite_user.field.distribution_of_rights.label',
                defaultMessage: 'Rights distribution',
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.switchWrapper}>
              <Box className={classes.switchFieldAndLabel}>
                <Field
                  color='primary'
                  type='checkbox'
                  component={FormikSwitch}
                  onChange={handleChangeRole}
                  checked={invitation.role === 'Admin'}
                />
                <SwitchLabel
                  title={intl.formatMessage({
                    id: 'form.invite_user.field.user_to_admin.label',
                    defaultMessage: 'Appoint user as admin',
                  })}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
