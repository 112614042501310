import {
  GridColDef,
  GridColumnsState,
  GridSortModel,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import csvDownload from 'json-to-csv-export';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';

type ExportedRow = Record<string, string | number>;

const sortData = (data: GridValidRowModel[], sortOrder?: GridSortModel): GridValidRowModel[] => {
  if (!sortOrder?.[0]) return data;

  const { field, sort } = sortOrder[0];
  return [...data].sort((a, b) => {
    const compare = a[field] > b[field] ? 1 : -1;
    return sort === 'asc' ? compare : -compare;
  });
};

const exportData = (dataToExport: ExportedRow[], type: 'CSV' | 'EXCEL' | 'PDF') => {
  switch (type) {
    case 'EXCEL': {
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
      XLSX.writeFile(workbook, 'export.xlsx');
      break;
    }
    case 'CSV':
      csvDownload({ data: dataToExport, filename: 'export', delimiter: ';' });
      break;
    case 'PDF': {
      const doc = new jsPDF();
      autoTable(doc, {
        head: [Object.keys(dataToExport[0])],
        body: dataToExport.map(row => Object.values(row)),
        theme: 'striped',
      });
      doc.save('export.pdf');
      break;
    }

    default:
      console.error('Invalid export type');
  }
};

const formatDataForExport = (
  data: GridValidRowModel[],
  columnsFieldOrderState: string[],
  colDef: GridColDef[],
  gridColumnState: GridColumnsState,
): ExportedRow[] =>
  data.map(row => {
    const exportRow: ExportedRow = {};

    columnsFieldOrderState.forEach(fieldName => {
      const column = colDef.find(col => col.field === fieldName);
      if (!column?.headerName || gridColumnState.columnVisibilityModel?.[column.field] === false)
        return;

      let rowValue = row[column.field] ?? '';
      if (column.valueGetter) {
        rowValue = column.valueGetter(rowValue as never, row, column, undefined as never);
      }
      if (column.valueFormatter) {
        rowValue = column.valueFormatter(rowValue as never, row, column, undefined as never);
      }

      exportRow[column.headerName] = rowValue;
    });

    return exportRow;
  });

const exportHelper = (
  data: GridValidRowModel[],
  colDef: GridColDef[],
  gridColumnState: GridColumnsState,
  type: 'CSV' | 'EXCEL' | 'PDF',
  sortOrder?: GridSortModel,
) => {
  if (!data?.length) return;

  const sortedData = sortData(data, sortOrder);
  const columnsFieldOrderState = gridColumnState.orderedFields || colDef.map(col => col.field);
  const dataToExport = formatDataForExport(
    sortedData,
    columnsFieldOrderState,
    colDef,
    gridColumnState,
  );

  exportData(dataToExport, type);
};

export default exportHelper;
