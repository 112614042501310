import 'moment/locale/de';

import { Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { ConnectedFocusError } from 'focus-formik-error';
import { Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import qs from 'qs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SpinnerLoader } from '@/Components/Loader';
import { CourseManagerTypes, useAppContext } from '@/Context';
import { useConfirmDialog } from '@/Hooks/useConfirmDialog';
import { useCurrentLanguage } from '@/Hooks/useCurrentLanguage';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import { getEventsPathname } from '@/Mappers';
import { useGetPartnerQuery } from '@/Queries';
import { EventsServiceFactory, PartnerCoursesServiceFactory } from '@/Services';
import { CourseTypes, Event, EventSeriesSettings, Partner } from '@/Types';
import { EventValidation } from '@/Utils';

import { AppointmentsForm } from './AppointmentsForm';
import { LocationInfoForm } from './LocationInfoForm';
import { StreamInfoForm } from './StreamInfoForm';

type NewEventsFormProps = {
  casPublicId: Partner['casPublicId'];
  courseId: number;
  refCallback: (ref: React.RefObject<FormikProps<Event>>, tabNumber: number) => void;
  handleNewEventsForm?: (value: boolean) => void;
  handleToastMessage: (value: boolean) => void;
  handleShowEventForm: (value: boolean) => void;
  handleAsyncErrorMessage: (message: string) => void;
  asyncEventFormErrorMessage?: string;
  handlePrimaryCtaDisabled: (value: boolean) => void;
  handleSecondaryCtaDisabled: (value: boolean) => void;
};

export const EventForm = ({
  refCallback,
  casPublicId,
  handleToastMessage,
  courseId,
  handleShowEventForm,
  handleAsyncErrorMessage,
  asyncEventFormErrorMessage,
  handlePrimaryCtaDisabled,
  handleSecondaryCtaDisabled,
}: NewEventsFormProps) => {
  const intl = useMemoedIntl();
  const navigate = useNavigate();

  const {
    state: {
      courseManager: { course },
    },
    dispatch,
  } = useAppContext();
  const { data: partner } = useGetPartnerQuery();

  const location = useLocation();
  const formikRef = useRef<FormikProps<Event>>(null);
  const handleConfirmDialog = useConfirmDialog();
  const currentLanguage = useCurrentLanguage();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [isLoading, setIsLoading] = useState(false);

  const eventsService = new EventsServiceFactory().getInstance(dispatch);
  const partnerCoursesService = new PartnerCoursesServiceFactory().getInstance(dispatch);

  const [initialState] = useState<Event>(
    eventsService.getInitialEventDataType({
      duration: course?.courseDuration,
      capacity: course?.capacity,
      courseType: course?.courseType,
      partner: partner || undefined,
    }),
  );

  useEffect(() => {
    refCallback(formikRef, 1);
  }, [refCallback, formikRef]);

  const createEvent = useCallback(
    async (values: Event) => {
      setIsLoading(true);
      const time =
        values.appointments.hour?.padStart(2, '0') +
        ':' +
        values.appointments.minute?.padStart(2, '0');

      const modifiedValues: Event = {
        ...values,
        appointments: {
          ...values.appointments,
          time,
          seriesSettings: {
            ...(values.appointments.seriesSettings || ({} as EventSeriesSettings)),
          },
          duration: parseInt(values.appointments.duration.toString()),
          capacity: parseInt(values.appointments.capacity.toString()),
          startDate: values.appointments.startDate
            ? moment(values.appointments.startDate).locale('de').format('YYYY-MM-DD')
            : null,
        },
      };

      delete modifiedValues.appointments.hour;
      delete modifiedValues.appointments.minute;

      if (!modifiedValues.isSeries) {
        delete modifiedValues.appointments.seriesSettings;
      } else {
        if (modifiedValues.appointments.seriesSettings) {
          if (modifiedValues.appointments.seriesSettings.endDate) {
            modifiedValues.appointments.seriesSettings.endDate = moment(
              modifiedValues.appointments.seriesSettings.endDate,
            )
              .locale('de')
              .format('YYYY-MM-DD');
          }

          modifiedValues.appointments.seriesSettings.repetitions = parseInt(
            modifiedValues.appointments.seriesSettings.repetitions.toString(),
          );
        }
      }

      delete modifiedValues.isSeries;

      if (formikRef.current?.status.action === 'SAVE') {
        modifiedValues.published = true;
      }
      if (formikRef.current?.status.action === 'DRAFT') {
        modifiedValues.published = false;
      }

      if (
        modifiedValues.eventType === CourseTypes.ONSITE &&
        values.eventType === CourseTypes.ONSITE
      ) {
        modifiedValues.address = { ...values.address };
        delete modifiedValues.address.isPartnerAddress;
      }

      let response;
      if (modifiedValues.eventType === CourseTypes.ONSITE) {
        const requestData = {
          published: modifiedValues.published,
          appointments: modifiedValues.appointments,
          address: modifiedValues.address,
          additionalInformation: modifiedValues.additionalInformation,
        };
        response = await eventsService.createOnsiteEvent(casPublicId, courseId, requestData);
      } else {
        const requestData = {
          published: modifiedValues.published,
          appointments: modifiedValues.appointments,
          streamSettings: modifiedValues.streamSettings,
        };
        response = await eventsService.createEvent(casPublicId, courseId, requestData);
      }

      if (!response.isAxiosError) {
        const courseResponse = await partnerCoursesService.getCourse(casPublicId, courseId);

        dispatch({
          type: CourseManagerTypes.SET_COURSE,
          payload: { course: courseResponse.data },
        });

        handleConfirmDialog(false);

        const pathname = getEventsPathname(currentLanguage, courseId);

        handleToastMessage(true);
        handleShowEventForm(false);

        navigate(pathname);
      } else {
        handleAsyncErrorMessage(response.message);
      }

      setIsLoading(false);
    },
    [
      eventsService,
      casPublicId,
      courseId,
      partnerCoursesService,
      dispatch,
      handleConfirmDialog,
      currentLanguage,
      handleToastMessage,
      handleShowEventForm,
      navigate,
      handleAsyncErrorMessage,
    ],
  );

  useEffect(() => {
    handleAsyncErrorMessage('');
    if (course) {
      const capacity = course.capacity;
      if (formikRef.current && formikRef.current.values.appointments.capacity === '') {
        formikRef.current?.setFieldValue('appointments.capacity', capacity);
      }
    }

    if (queryParams.asyncErrorMessage) {
      handleAsyncErrorMessage(queryParams.asyncErrorMessage as string);
    }
  }, [course, handleAsyncErrorMessage, queryParams.asyncErrorMessage]);

  const handleFormSubmit = (values: Event) => {
    const updatedData = { ...values };

    if (course?.courseType === CourseTypes.ONLINE && 'streamSettings' in updatedData) {
      if (updatedData.streamSettings.streamLink) {
        updatedData.streamSettings.streamLink = 'https://' + updatedData.streamSettings.streamLink;
      }
    }

    return createEvent(updatedData);
  };

  // Trigger validation on language change
  useEffect(() => {
    formikRef?.current?.validateForm();
  }, [intl.locale]); // Run when the language changes

  if (!course) {
    return null;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {isLoading && <SpinnerLoader isFullScreen />}
      <Formik
        innerRef={formikRef}
        validationSchema={() => EventValidation(intl, course?.courseType)}
        validateOnChange
        validateOnBlur
        validateOnMount
        initialValues={initialState}
        onSubmit={handleFormSubmit}>
        {() => (
          <Form>
            <ConnectedFocusError />
            <Grid container spacing={2}>
              <AppointmentsForm
                eventsService={eventsService}
                course={course}
                asyncEventFormErrorMessage={asyncEventFormErrorMessage}
                handlePrimaryCtaDisabled={handlePrimaryCtaDisabled}
                handleSecondaryCtaDisabled={handleSecondaryCtaDisabled}
              />
              {course.courseType === CourseTypes.ONSITE && <LocationInfoForm />}
              {course.courseType === CourseTypes.ONLINE && <StreamInfoForm />}
            </Grid>
          </Form>
        )}
      </Formik>
    </LocalizationProvider>
  );
};
