import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormSnackbar } from '@/App/Shared/Notification/Components/FormSnackbar';
import { useConfirmDialog } from '@/Hooks/useConfirmDialog';
import { useCurrentLanguage } from '@/Hooks/useCurrentLanguage';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import { getInviteUsersPathname } from '@/Mappers';
import { useUpdateUserDataMutation } from '@/Queries';
import { useGetActiveUsersQuery } from '@/Services';
import { AdministrationFormik, FormProps } from '@/Types';
import handleCatchError from '@/Utils/handleCatchError';
import { AdministrationTable } from '@/Views/Users/Components/AdministrationTable/AdministrationTable';

import { Container } from './AdministrationForm.styles';

export const AdministrationForm = ({
  refCallback,
  casPublicId,
  currentUserId,
  handleToastMessage,
  handlePrimaryCtaDisabled,
}: FormProps) => {
  const navigate = useNavigate();
  const currentLanguage = useCurrentLanguage();
  const { data: users = [], isLoading } = useGetActiveUsersQuery(
    { publicId: casPublicId, cognitoId: currentUserId || '' },
    { skip: !casPublicId, refetchOnMountOrArgChange: true },
  );
  const { formatMessage } = useMemoedIntl();
  const formikRef = useRef<FormikProps<AdministrationFormik>>(null);

  const [openToastMessage, setOpenToastMessage] = useState(false);
  const [customToastMessage, setCustomToastMessage] = useState<string | undefined>();
  const handleConfirmDialog = useConfirmDialog();

  const { mutateAsync: updateUserData } = useUpdateUserDataMutation();

  const handleAddNewUsers = useCallback(() => {
    navigate(getInviteUsersPathname(currentLanguage));
  }, [navigate, currentLanguage]);

  useEffect(() => {
    refCallback(formikRef, 0);
  }, [refCallback, formikRef]);

  const handleCloseToastMessage = useCallback(() => {
    setOpenToastMessage(false);
    setCustomToastMessage(undefined);
  }, []);

  const handleOpenToastMessage = useCallback((message?: string) => {
    setOpenToastMessage(true);
    if (message) {
      setCustomToastMessage(message);
    }
  }, []);

  useEffect(() => {
    if (formikRef.current) {
      const { setFieldValue } = formikRef.current;
      setFieldValue('users', users);
    }
  }, [users]);

  const handleSubmit = async (
    { updatedUser }: AdministrationFormik,
    formik: FormikHelpers<AdministrationFormik>,
  ) => {
    if (updatedUser) {
      try {
        await updateUserData({ updatedUser });

        handleToastMessage(
          true,
          formatMessage({
            id: 'form.successfully_saved_changes',
            defaultMessage: 'Änderungen erfolgreich gespeichert',
          }),
        );
        formik.setFieldValue('updatedUser', null);
        handleConfirmDialog(false);
      } catch (error) {
        handleCatchError(error);
      }
    }
  };

  return (
    <>
      <FormSnackbar
        customMessage={customToastMessage}
        openToastMessage={openToastMessage}
        handleCloseToastMessage={handleCloseToastMessage}
      />

      <Formik
        innerRef={formikRef}
        validateOnBlur={false}
        validateOnChange
        initialValues={{
          updatedUser: null,
          users,
        }}
        onSubmit={handleSubmit}>
        {() => (
          <Form>
            <Container>
              <AdministrationTable
                handleAddNewUsers={handleAddNewUsers}
                handlePrimaryCtaDisabled={handlePrimaryCtaDisabled}
                handleOpenToastMessage={handleOpenToastMessage}
                currentUserId={currentUserId || ''}
                casPublicId={casPublicId}
                isLoading={isLoading}
              />
            </Container>
          </Form>
        )}
      </Formik>
    </>
  );
};
