import { FormikHelpers, FormikProps } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  AddressAndContactForm,
  CheckInOptionsForm,
  Header,
  PartnerCategoriesForm,
  PreviewModal,
  SpinnerLoader,
  TabPanelNavigation,
} from '@/Components';
import { useAppContext } from '@/Context';
import useStudioProfileBreadcrumbs from '@/Hooks/Breadcrumbs/useStudioProfileBreadcrumbs';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import { getCommonInformationTabs, getCommonInfoTabIdx, getCommonInfoTabName } from '@/Mappers';
import {
  useCurrentPartnerIdQuery,
  useCurrentUserInfoQuery,
  useGetPartnerQuery,
  usePartnerCategoriesQuery,
} from '@/Queries';
import InfoIcon from '@/Static/Icons/info_delay_icon.svg';
import {
  AddressAndContactPartialType,
  CategoriesAndTagsPartial,
  CheckInOptionsPartial,
} from '@/Types';
import handleCatchError from '@/Utils/handleCatchError';

import { CtaFooter } from '../../App/Shared/Footer/CtaFooter';
import { DelayModal } from '../../App/Shared/Modal/DelayModal';
import { FormSnackbar } from '../../App/Shared/Notification/Components/FormSnackbar';
import { useCurrentLanguage } from '../../Hooks/useCurrentLanguage';

const StudioProfileInfo = () => {
  const intl = useMemoedIntl();
  const currentLanguage = useCurrentLanguage();
  const { tab = '' } = useParams<{ tab: string }>();
  const navigate = useNavigate();
  const {
    state: { delayModal },
  } = useAppContext();
  const [currentTab, setCurrentTab] = useState(getCommonInfoTabIdx(tab));
  const [openToastMessage, setOpenToastMessage] = useState(false);
  const [addressAndContactFormRef, setAddressAndContactFormRef] =
    useState<FormikHelpers<AddressAndContactPartialType>>();
  const [checkInOptionsFormRef, setCheckInOptionsFormRef] =
    useState<FormikHelpers<CheckInOptionsPartial>>();
  const [partnerCategoriesFormRef, setPartnerCategoriesFormRef] =
    useState<FormikHelpers<CategoriesAndTagsPartial>>();
  const [primaryCtaDisabled, setPrimaryCtaDisabled] = useState(true);
  const [showPreview, setShowPreview] = useState(false);

  const { data: partnerData, isLoading: isLoadingPartnerData } = useGetPartnerQuery();
  const { data: userAttributes, isLoading: isLoadingUserAttributes } = useCurrentUserInfoQuery();
  const { data: casPublicId, isLoading: isLoadingCasPublicId } = useCurrentPartnerIdQuery();
  const { data: partnerCategories, isLoading: isLoadingPartnerCategories } =
    usePartnerCategoriesQuery({ currentLanguage });

  const handlePrimaryCtaDisabled = useCallback((value: boolean) => {
    setPrimaryCtaDisabled(value);
  }, []);

  const handleTabChange = useCallback(
    (_, newValue: number) => {
      const tabName = getCommonInfoTabName(newValue);
      navigate(`/${currentLanguage}/allgemeine-informationen/${tabName}`);
    },
    [navigate, currentLanguage],
  );

  const handleCloseToastMessage = useCallback(() => {
    setOpenToastMessage(false);
  }, []);

  const handleOpenToastMessage = useCallback(
    (openToastMessage: boolean) => {
      if (!delayModal.notShowProfileModal) {
        return;
      }
      setOpenToastMessage(openToastMessage);
    },
    [delayModal.notShowProfileModal],
  );

  useEffect(() => {
    const newTab = getCommonInfoTabIdx(tab);
    setCurrentTab(newTab);
    setPrimaryCtaDisabled(true);
  }, [tab]);

  const receiveRef = useCallback(
    <T extends AddressAndContactPartialType | CheckInOptionsPartial | CategoriesAndTagsPartial>(
      ref: React.MutableRefObject<FormikProps<T> | null>,
      tabNumber: number,
    ) => {
      if (ref && ref.current) {
        if (tabNumber === 0) {
          setAddressAndContactFormRef(
            ref.current as unknown as FormikProps<AddressAndContactPartialType>,
          );
          setPrimaryCtaDisabled(true);
        }

        if (tabNumber === 1) {
          setCheckInOptionsFormRef(ref.current as unknown as FormikProps<CheckInOptionsPartial>);
          setPrimaryCtaDisabled(true);
        }

        if (tabNumber === 2) {
          setPartnerCategoriesFormRef(
            ref.current as unknown as FormikProps<CategoriesAndTagsPartial>,
          );
          setPrimaryCtaDisabled(true);
        }
      }
    },
    [setAddressAndContactFormRef, setCheckInOptionsFormRef, setPartnerCategoriesFormRef],
  );

  const handleSubmit = useCallback(async () => {
    try {
      if (currentTab === 0 && addressAndContactFormRef) {
        await addressAndContactFormRef.submitForm();
      }

      if (currentTab === 1 && checkInOptionsFormRef) {
        await checkInOptionsFormRef.submitForm();
      }

      if (currentTab === 2 && partnerCategoriesFormRef) {
        await partnerCategoriesFormRef.submitForm();
      }
    } catch (error: unknown) {
      handleCatchError(error);
    }
  }, [currentTab, addressAndContactFormRef, checkInOptionsFormRef, partnerCategoriesFormRef]);

  const handleShowPreview = useCallback(() => {
    setShowPreview(true);
  }, []);

  const handleClosePreview = useCallback(() => {
    setShowPreview(false);
  }, []);

  const currentBreadcrumbs = useStudioProfileBreadcrumbs(currentTab);

  const isLoading =
    isLoadingUserAttributes ||
    isLoadingCasPublicId ||
    isLoadingPartnerCategories ||
    isLoadingPartnerData;

  return (
    <>
      {isLoading && <SpinnerLoader isFullScreen />}
      <Header
        showLanguageSwitcher={currentTab === 1}
        callToActions={{
          primary: {
            title: intl.formatMessage({
              id: 'cta.save_and_publish',
              defaultMessage: 'Save & Publish',
            }),
            callback: handleSubmit,
            disabled: primaryCtaDisabled,
            variant: 'contained',
          },
          secondary: {
            title: intl.formatMessage({
              id: 'cta.preview_partner',
              defaultMessage: 'Preview Studio Profile',
            }),
            callback: handleShowPreview,
            disabled: false,
          },
        }}
        handleTabChange={handleTabChange}
        currentTab={currentTab}
        header={intl.formatMessage({
          id: 'view.common_information',
          defaultMessage: 'General information',
        })}
        breadcrumbs={currentBreadcrumbs}
        tabs={getCommonInformationTabs()}
      />
      <FormSnackbar
        openToastMessage={openToastMessage}
        handleCloseToastMessage={handleCloseToastMessage}
        customMessage={intl.formatMessage({
          id: 'toast.save.info',
          defaultMessage:
            'Die Änderungen werden über Nacht umgesetzt und sind am nächsten Tag erst sichtbar.',
        })}
        customIcon={InfoIcon}
      />
      {casPublicId &&
        partnerData &&
        !isLoadingPartnerData &&
        partnerCategories &&
        userAttributes?.attributes && (
          <>
            <TabPanelNavigation value={currentTab} index={0}>
              <AddressAndContactForm
                partner={partnerData}
                casPublicId={casPublicId}
                refCallback={receiveRef}
                handlePrimaryCtaDisabled={handlePrimaryCtaDisabled}
                setOpenToastMessage={handleOpenToastMessage}
              />
            </TabPanelNavigation>

            <TabPanelNavigation value={currentTab} index={1}>
              <CheckInOptionsForm
                partner={partnerData}
                casPublicId={casPublicId}
                refCallback={receiveRef}
                handlePrimaryCtaDisabled={handlePrimaryCtaDisabled}
                setOpenToastMessage={handleOpenToastMessage}
              />
            </TabPanelNavigation>

            <TabPanelNavigation value={currentTab} index={2}>
              <PartnerCategoriesForm
                partner={partnerData}
                casPublicId={casPublicId}
                categories={partnerCategories}
                refCallback={receiveRef}
                handlePrimaryCtaDisabled={handlePrimaryCtaDisabled}
                setOpenToastMessage={handleOpenToastMessage}
              />
            </TabPanelNavigation>
          </>
        )}
      <CtaFooter
        callToActions={{
          primary: {
            title: intl.formatMessage({
              id: 'cta.save_and_publish',
              defaultMessage: 'Save & Publish',
            }),
            callback: handleSubmit,
            disabled: primaryCtaDisabled,
            variant: 'contained',
          },
        }}
      />
      {delayModal.open && (
        <DelayModal
          open
          title={intl.formatMessage({
            id: 'delayModal.title.info',
            defaultMessage: 'Verzögerte Sichtbarkeit',
          })}
          iconSrc={InfoIcon}
          content={intl.formatMessage({
            id: 'delayModal.info',
            defaultMessage:
              'Deine Änderungen im Hansefit Verbundpartner-Portal werden über Nacht umgesetzt. Somit sind die Änderungen erst am nächsten Tag in der Hansefit App oder auf der Website sichtbar.',
          })}
        />
      )}
      {showPreview && <PreviewModal onClose={handleClosePreview} />}
    </>
  );
};

export default StudioProfileInfo;
