import { Grid } from '@mui/material';
import { Field } from 'formik';
import { useIntl } from 'react-intl';

import { FormikErrorMessage } from '@/App/Shared/Form/Components/Formik/FormikErrorMessage';
import { FormikTextField } from '@/App/Shared/Form/Components/Formik/FormikTextField';

import { useUserFormStyles } from '../RegisterPartner/Steps/Steps.styles';

const UserDataFields = () => {
  const { classes } = useUserFormStyles();
  const { formatMessage } = useIntl();

  return (
    <>
      <Grid item xs={12}>
        <Field
          className={classes.textField}
          component={FormikTextField}
          variant='outlined'
          name='name'
          type='text'
          label={formatMessage({
            id: 'form.register.stepper.step.registration.field.name.label',
            defaultMessage: 'First name',
          })}
        />
        <FormikErrorMessage name='name' />
      </Grid>

      <Grid item xs={12}>
        <Field
          className={classes.textField}
          component={FormikTextField}
          variant='outlined'
          name='surname'
          type='text'
          label={formatMessage({
            id: 'form.register.stepper.step.registration.field.surname.label',
            defaultMessage: 'Last name',
          })}
        />
        <FormikErrorMessage name='surname' />
      </Grid>

      <Grid item xs={12}>
        <Field
          className={classes.textField}
          component={FormikTextField}
          variant='outlined'
          name='email'
          type='email'
          label={formatMessage({
            id: 'form.register.stepper.step.registration.field.email.label',
            defaultMessage: 'E-mail address',
          })}
        />
        <FormikErrorMessage name='email' />
      </Grid>
    </>
  );
};

export default UserDataFields;
