import { Box, Dialog, DialogActions, DialogContent, IconButton } from '@mui/material';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';

import { Button } from '@/Components';
import { AppContext, ConfirmationDialogTypes } from '@/Context';
import CloseIcon from '@/Static/Icons/atoms-symbols-category-close-big.svg';

import FigmaDesignTokens from '../../../design/design-tokens.json';
import { FormHeader } from '../../Shared/Form/Components/Header/FormHeader';

type LeavingGuardModalProps = {
  onCancel: (value?: unknown) => void;
  onConfirm: (value?: unknown) => void;
};

export const useStyles = makeStyles()(() => ({
  dialogContent: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 20,
  },
  headerWrapper: {
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  ctaWrapper: {
    display: 'flex',
    paddingTop: 20,
    paddingBottom: 50,
    paddingRight: 40,
  },
  closeButton: {
    padding: 0,
    marginLeft: 20,
  },
  confirmButton: {
    color: FigmaDesignTokens.Grey[500],
    marginRight: 20,
    '& button': {
      lineHeight: 'normal',
    },
  },
}));

export const LeavingGuardModal = (props: LeavingGuardModalProps): JSX.Element => {
  const { onConfirm, onCancel } = props;
  const { classes } = useStyles();
  const { state, dispatch } = React.useContext(AppContext);
  const handleConfirm = useCallback(
    (onConfirm: () => void) => {
      dispatch({
        type: ConfirmationDialogTypes.SET_CONFIRMATION_DIALOG,
        payload: { show: false },
      });
      onConfirm();
    },
    [dispatch],
  );
  const { formatMessage } = useIntl();

  return (
    <Dialog open={state.confirmationDialog.show} onClose={onCancel} maxWidth='sm'>
      <Box className={classes.headerWrapper}>
        <FormHeader
          title={formatMessage({
            id: 'leaving_guard_modal.header',
            defaultMessage: 'Unsave changes',
          })}
        />
        <IconButton aria-label='close' onClick={onCancel} className={classes.closeButton}>
          <img alt='' src={CloseIcon} />
        </IconButton>
      </Box>

      <DialogContent className={classes.dialogContent}>
        {formatMessage({
          id: 'leaving_guard_modal.content',
          defaultMessage: `Are you sure you want to leave this page?
                          You have made changes that have not yet been saved. Please click "Save & Publish" to save your changes.`,
        })}
      </DialogContent>
      <DialogActions>
        <Box className={classes.ctaWrapper}>
          <Button
            variant='outlined'
            className={classes.confirmButton}
            onClick={() => handleConfirm(onConfirm)}>
            {formatMessage({
              id: 'leaving_guard_modal.cta.leave_page',
              defaultMessage: 'Discard changes',
            })}
          </Button>
          <Button onClick={onCancel}>
            {formatMessage({
              id: 'leaving_guard_modal.cta.stay_on_page',
              defaultMessage: 'Stay on the page',
            })}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
